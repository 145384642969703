import {
  BrowserRouter,
  Routes,
  Route,
  Outlet
} from "react-router-dom";

import MobileAppDownloadPage from 'containers/MobileAppDownloadPage'
import SwaggerDoc from 'containers/SwaggerDoc'

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* https://app.shtygen.com/users/signup?invitation=AX7H7qqa */}
        <Route path="/*" element={<MobileAppDownloadPage />} />
        <Route
          path="/swagger"
          element={<SwaggerDoc />}
        />
      </Routes>
      <Outlet />
    </BrowserRouter>
  );
};

export default App;
