import { useEffect } from 'react'
import SwaggerUI from 'swagger-ui-react'
import "swagger-ui-react/swagger-ui.css"

const SWAGGER_DOC_URL = `${process.env.REACT_APP_API_HOST}/api/v1/swagger_doc`

const setBodyBg = (color) => document.documentElement.style.setProperty('--body-bg', color)

const SwaggerDoc = () => {
  useEffect(() => {
    setBodyBg('#FFF')
    return setBodyBg('$blue')
  })

  return (
    <SwaggerUI url={SWAGGER_DOC_URL} />
  )
}

export default SwaggerDoc
