import { useEffect } from 'react';
import {
  useLocation,
  useParams
} from "react-router-dom";
import { osName } from "react-device-detect";

import AppStoreButtonSVG from 'images/svg/app-store-button'
import GooglePlayButtonSVG from 'images/svg/google-play-button'

const StoreLinks = {
  "Android": process.env.REACT_APP_ANDROID_LINK,
  "iOS": process.env.REACT_APP_IOS_LINK,
}

const MobileAppDownloadPage = () => {
  const location = useLocation()
  const params = useParams()

  useEffect(() => {
    if (StoreLinks[osName]) {
      window.open(StoreLinks[osName], '_top');
    };
  }, [location, params]);

  return (
    <div style={{ margin: 'auto', padding: '50px 20px 0', maxWidth: '680px', textAlign: 'center', height: "100vh" }}>
      <h1>Welcome to {process.env.REACT_APP_NAME}!</h1>
      <h4>
        It looks like you haven't installed the app.
      </h4>
      <p>
        Please download the application and then click on the link received in the SMS again.
      </p>
      <div style={{ margin: 'auto', maxWidth: '440px', textAlign: 'center' }}>

        <div style={{ display: 'flex', flex: 'wrap', flexFlow: 'column', maxWidth: '200px', justifyContent: 'center', margin: 'auto', marginTop: '5em' }}>
          {StoreLinks["Android"] && (
            <a href={StoreLinks["Android"]} target="_top" style={{ display: 'block', width: '100%', height: '100px' }}>
              <GooglePlayButtonSVG />
            </a>
          )}

          {StoreLinks["iOS"] && (
            <a href={StoreLinks["iOS"]} target="_top" style={{ display: 'block', width: '100%', height: '100px' }}>
              <AppStoreButtonSVG />
            </a>
          )}
        </div>
      </div>
    </div>
  );
}

export default MobileAppDownloadPage;
